import classNames from 'classnames'
import React from 'react'
import Field2, { type Field2Props } from 'components/Field2'
import copy from 'constants/copy'
import styles from './FieldSmsConsentCheckbox.module.scss'

type Props = {
  disclaimerIsOptional?: boolean
  shortenedText?: boolean
  // eslint-disable-next-line no-unused-vars
  onChange?: (isChecked: boolean) => void
} & Partial<Field2Props>

export default function FieldSmsConsentCheckbox({
  className,
  disclaimerIsOptional,
  shortenedText,
  onChange,
  ...props
}: Props) {
  function handleChange(isChecked: boolean) {
    if (onChange) {
      onChange(isChecked)
    }
  }

  return (
    <Field2
      label={
        disclaimerIsOptional
          ? copy.PHONE_NUMBER_DISCLAIMER_OPTIONAL
          : shortenedText
            ? copy.PHONE_NUMBER_DISCLAIMER_SHORTENED
            : copy.PHONE_NUMBER_DISCLAIMER
      }
      //labelColor="inherit"
      //labelSize="small"
      name="smsConsent"
      type="checkbox"
      {...props}
      className={classNames('FieldSmsConsentCheckbox', styles.this, className)}
      onCheckboxChange={handleChange}
    />
  )
}
